import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(43)};
  height: ${calculateResponsiveSize(72)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--blue);
  transition: 1s;
  ${mediaMax('mobile')} {
    width: 43px;
    height: 72px;
  }
`
export const Wrap = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 20%;
  display: flex;
  z-index: 2;
  padding: ${calculateResponsiveSize(36)};
  justify-content: space-between;
  align-items: flex-start;
  transform: translateY(-100%);
  &.white {
    ${Logo} {
      background: var(--white);
    }
  }

  &.right {
    right: 0;
    margin-left: auto;
    justify-content: flex-end;
    padding-top: ${calculateResponsiveSize(46)};
    transform: translateY(0%);
  }
  &.mobile {
    transform: translateY(0%);
    top: 100dvh;
    position: absolute;
  }
  &.legal {
    transform: translateY(0%) !important;
    display: none;
    &.right {
      display: flex;
      top: 0;
    }
  }

  ${mediaMax('mobile')} {
    padding: 25px;
    width: 27%;
    &.right {
      padding-top: 46px;
    }
    &.mobile {
      &.right {
        top: 0;
      }
    }
  }
`
