import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import FooterLogo from 'icons/footer-logo.svg'

import instagramIcon from 'icons/instagram.svg'

export const Text = css`
  font-family: 'GT Ultra';
  font-size: ${calculateResponsiveSize(9)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(10)};
  letter-spacing: ${calculateResponsiveSize(2)};
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  color: var(--blue);
  ${mediaMax('mobile')} {
    font-size: 9px;
    line-height: 10px;
    letter-spacing: 2px;
  }
`
export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(165)};
  height: ${calculateResponsiveSize(24)};
  background: url(${FooterLogo}) no-repeat;
  background-size: contain !important;

  ${mediaMax('mobile')} {
    width: 165px;
    height: 24px;
  }
`
export const LinkItem = styled.a`
  ${Text}
  font-weight: 300;
`
export const LegalLink = styled((props) => <Link {...props} />)`
  ${Text}
  font-weight: 300;
`
export const Contact = styled.div`
  display: flex;
  font-family: 'GT Ultra';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(10)};
  letter-spacing: 0px;
  text-align: center;
  gap: ${calculateResponsiveSize(24)};
  & a {
    text-decoration: none;
    color: var(--blue);
  }
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 10px;
    gap: 12px 16px;
    flex-wrap: wrap;
    justify-content: center;
    a.phone {
      order: 5;
    }
  }
`

export const Icon = styled.a`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(12)};
  display: block;
  cursor: pointer;
  background: var(--blue);
  position: absolute;
  top: ${calculateResponsiveSize(26)};
  right: ${calculateResponsiveSize(60)};

  mask-image: url(${instagramIcon});
  -webkit-mask-image: url(${instagramIcon});
  ${mediaMax('mobile')} {
    width: 12px;
    height: 12px;
    right: 25px;
    top: 47px;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(20)};

  ${mediaMax('mobile')} {
    gap: 6px 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Wrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${calculateResponsiveSize(20)};
  color: var(--blue);
  gap: ${calculateResponsiveSize(24)};
  margin-top: auto;
  ${mediaMax('mobile')} {
    padding: 42px 80px 18px;
    gap: 24px;
  }
`
