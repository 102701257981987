import React, { useContext } from 'react'
import { gsap, ScrollSmoother } from 'helpers/gsap'
import { navigate } from 'gatsby'

import Button from '../ui/button'
import { ThemeContext } from '../../helpers/ThemeProvider'

import { Wrap, Logo } from './index.styled'

const Header = ({ className, isBack = false }) => {
  const { siteTheme, scrollerSmoother, setResetForm } = useContext(ThemeContext)

  const onContact = () => {
    setResetForm(true)
    if (scrollerSmoother) {
      gsap.to(scrollerSmoother.current, {
        scrollTop: document.querySelector(`#contact-form`)?.offsetTop,
        duration: 1,
      })
    }
  }

  return (
    <>
      <Wrap className={[className, siteTheme].join(' ')} id={'header'}>
        <Logo to="/" />
      </Wrap>
      <Wrap
        className={[className, siteTheme, 'right'].join(' ')}
        id={'headerRight'}
      >
        {isBack ? (
          <Button
            className={'beige'}
            title={'Back'}
            as={'button'}
            type={'button'}
            onClick={() => {
              navigate(-1)
            }}
          >
            Back
          </Button>
        ) : (
          <Button
            as="button"
            onClick={onContact}
            title={'Contact'}
            className={siteTheme}
          />
        )}
      </Wrap>
    </>
  )
}
export default Header
