import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import DecorIcon from '../../../icons/button-decor.svg'

export const Element = styled((props) => <Link {...props} />)`
  border: 1px solid var(--blue);
  font-family: 'GT Ultra';
  font-weight: 700;
  letter-spacing: 2px;
  width: fit-content;

  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(12)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(8)} ${calculateResponsiveSize(16)};
  cursor: pointer;
  text-decoration: none;

  transition: background 0.5s ease;

  color: var(--blue);
  position: relative;
  background: transparent;

  &:visited {
    color: currentColor;
  }

  .decor {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: ${calculateResponsiveSize(73)};
    height: ${calculateResponsiveSize(10)};
    bottom: -${calculateResponsiveSize(4)};
  }
  &:hover {
    background: #001e601a;
  }
  &.white {
    color: var(--blue);
    border: 1px solid var(--white);
    background: white;
    &:hover {
      background: white;
    }
  }
  &.accept {
    margin-left: ${calculateResponsiveSize(20)};
  }
  &.wide {
    width: ${calculateResponsiveSize(160)};
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 16px;
    .decor {
      display: none;
    }
    &:hover {
    }
    &.wide {
      width: 160px;
    }
  }
`
