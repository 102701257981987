import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'

export const Element = styled((props) => <Link {...props} />)`
  border: 1px solid var(--blue);
  font-family: 'GT Ultra';
  font-weight: 700;
  letter-spacing: 2px;

  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(12)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(8)} ${calculateResponsiveSize(16)};
  cursor: pointer;
  text-decoration: none;

  background-image: linear-gradient(var(--blue), var(--blue));
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-color: transparent;
  transition: background-size 0s ease 0s, color 0.5s;

  color: var(--blue);
  &:visited {
    color: currentColor;
  }

  &:hover {
    background-size: 100% 100%;
    transition: background-size 0s ease 0.5s, color 0.5s;
    color: var(--beige);
  }
  &.mouseOver {
    animation-name: mouse-over;
    animation-duration: 0.5s;
  }
  &.mouseOut {
    animation-name: mouse-out;
    animation-duration: 0.5s;
  }

  &.white {
    border: 1px solid var(--white);
    background-image: linear-gradient(var(--white), var(--white));
    color: var(--white);
    &:hover {
      color: var(--blue);
    }
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
    padding: 8px 16px;
    background-color: transparent;
    &.beige {
      background-color: var(--beige);
    }
  }
`
