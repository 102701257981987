import React, { createContext, useCallback, useState } from "react";

export const ThemeContext = createContext();

const ThemeProvider = ({ children, scrollerSmoother }) => {
  const [siteTheme, setSiteTheme] = useState("");
  const [resetForm, setFormReset] = useState(false);

  const setTheme = useCallback((name) => {
    setSiteTheme(name);
  }, []);

  const setResetForm = useCallback((v) => {
    setFormReset(v);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        siteTheme,
        setTheme,
        scrollerSmoother,
        resetForm,
        setResetForm,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
