import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Logo,
  LinkItem,
  Contact,
  Icon,
  Links,
  LegalLink,
} from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { phone, email, address, links, insta },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          phone
          email
          address
          links {
            title
            url
            external
          }
          insta
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Logo to="/" />
      <Contact>
        <div>{address}</div>
        <a href={`tel:${phone}`} className={'phone'}>
          {phone}
        </a>
        <a href={`mailto:${email}`}>{email}</a>
      </Contact>
      <Links>
        <LegalLink to={'/legal'}>Legal</LegalLink>
        <LinkItem href={fhnFile} target={'_blank'} as="a" rel="noreferrer">
          FAIR HOUSING NOTICE
        </LinkItem>
        <LinkItem href={sopFile} target={'_blank'} as="a" rel="noreferrer">
          STANDARD OPERATING PROCEDURES
        </LinkItem>
        <Icon href={insta} target={'_blank'} as="a" />
      </Links>
    </Wrapper>
  )
}

export default Footer
