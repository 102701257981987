import React, { useRef } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import data from 'static/underline_blu.json'

import { Element } from './index.styled'

const ButtonWithDecor = ({ className, white = false, title, ...props }) => {
  const player = useRef(null)
  let start
  let end

  return (
    <Element
      className={[className, white ? 'white' : ''].join(' ')}
      onMouseEnter={(e) => {
        player.current.stop()
        player.current.play()
        clearTimeout(end)
        start = setTimeout(() => {
          player.current?.pause()
        }, 800)
      }}
      onMouseLeave={(e) => {
        clearTimeout(start)
        player.current.play()

        end = setTimeout(() => {
          player.current?.stop()
        }, 800)
      }}
      {...props}
    >
      {title}

      <Player ref={player} src={data} className={'decor'}></Player>
    </Element>
  )
}

export default ButtonWithDecor
