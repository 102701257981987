import React, { useState, useEffect } from 'react'

import { FullScreenImageSection, LogoWrap } from './index.styled'
import { Player } from '@lottiefiles/react-lottie-player'
import data from 'static/logomark_animation.json'

const MainIntro = ({ setIsFirstLoad = () => {} }) => {
  const [isFinish, seIsFinish] = useState(false)

  useEffect(() => {
    document.body.classList.add('hidden')
  }, [])

  const onFinish = () => {
    setTimeout(() => {
      seIsFinish(true)
    }, 1000)
    setTimeout(() => {
      document.body.classList.remove('hidden')
      setIsFirstLoad(false)
    }, 2000)
  }

  return (
    <FullScreenImageSection className={isFinish ? 'hide' : ''}>
      <LogoWrap>
        <Player
          keepLastFrame={true}
          autoplay
          onEvent={(event) => {
            if (event === 'complete') onFinish()
          }}
          src={data}
        ></Player>
      </LogoWrap>
    </FullScreenImageSection>
  )
}

export default MainIntro
