import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const LogoWrap = styled.div`
  width: ${calculateResponsiveSize(95)};
  ${mediaMax('mobile')} {
    width: 95px;
    max-width: 332px;
  }
  & > img {
    width: 100%;
  }
`

export const FullScreenImageSection = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  height: 100vh;
  z-index: 200;
  background: var(--beige);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${calculateResponsiveSize(32)};
  ${mediaMax('mobile')} {
    padding: 0 32px;
  }
  /* transition: 1s; */
  overflow: hidden;
  opacity: 1;

  &.hide {
    opacity: 0;
    transition: 1s;
  }
`
