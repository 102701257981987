import { createGlobalStyle } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

const GlobalStyle = createGlobalStyle`
  :root {
    --blue: #001E60;
    --beige: #F6F2ED;
    --white: #FFFFFF;

  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;

    font-family: "GT Ultra";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(18)};
    letter-spacing: 0;
    
    color: var(--blue);
    background: var(--beige);
    min-width: 320px;
    overflow-y: scroll;
    ${mediaMax('mobile')} {
      font-size: 12px;
    line-height: 18px;
    }
  }
  body.hidden {
    height: 100dvh !important;
  }
  body.hidden .home-wrapper {
    height: 100dvh;
    overflow: hidden;
  }
  .home-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;

    will-change: transform;
  }

  @-webkit-keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;

    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;

    }
  }
  @-webkit-keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;

    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;

    }
  }
  @keyframes fadeIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`

export default GlobalStyle
