import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import ThemeProvider from '../../helpers/ThemeProvider'
import HeaderTransition from '../ui/header-transition'
import Header from 'components/header'
import Footer from 'components/footer'
import MainIntro from '../ui/main-intro'
import CookiesBanner from '../ui/cookies-banner'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/globalStyles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import useWindowSize from '../../helpers/useWindowSize'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <HeaderTransition status={status}>
              <LayoutWrapper>{children}</LayoutWrapper>
            </HeaderTransition>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)
  const isHomePage = location.pathname === '/'
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const scrollerSmoother = React.useRef(null)
  const { isMobile } = useWindowSize()

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      scrollerSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 2,
        effects: true,
        normalizeScroll: true,
      })
      //pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
      //---------------------------
      if (location.pathname === '/' && !isMobile) {
        ScrollTrigger.create({
          trigger: '.home-content',
          start: 'top top-=5px',
          toggleActions: 'play none none reverse',
          animation: gsap
            .timeline()
            // .to('#headerRight', { y: '0', duration: 1 }, 0)
            .to('#header', { y: '0', duration: 1 }, 0),
        })
      }
      if (location.pathname === '/' && isMobile) {
        ScrollTrigger.create({
          trigger: '.home-content',
          start: 'top top',
          end: '+=1000%',
          pin: ['#headerRight'],
          pinSpacing: false,
          anticipatePin: 1,
        })
      }
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children, isMobile])

  return (
    <ThemeProvider scrollerSmoother={scrollerSmoother}>
      <div className="home-wrapper" ref={refWrapper}>
        {isHomePage && isFirstLoad && (
          <MainIntro setIsFirstLoad={setIsFirstLoad} />
        )}
        <CookiesBanner />

        {!isMobile && (
          <Header
            isBack={location.pathname === '/legal/'}
            scrollerSmoother={scrollerSmoother}
            className={[location.pathname == '/legal/' && 'legal'].join(' ')}
          />
        )}
        <div className="home-content">
          {isMobile && (
            <Header
              isBack={location.pathname === '/legal/'}
              scrollerSmoother={scrollerSmoother}
              className={[
                location.pathname == '/legal/' && 'legal',
                'mobile',
              ].join(' ')}
            />
          )}
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
